import React, {useEffect, useRef, useState} from 'react'
import {
  GoogleMap,
  MarkerProps as BaseMarkerProps,
  useLoadScript,
  MarkerClustererF,
  MarkerF,
} from '@react-google-maps/api'
import {
  ChargingConnectorType,
  ServiceDeliveryPoint,
  ServiceDeliveryPointAvailability,
  ServiceDeliveryPointOperator,
  ServiceDeliveryPointSearchResponse,
  ServiceDeliveryPointType,
} from 'types/api-types'
import ServicePointTypeToggle from './ServicePointTypeToggle'
import ServicePointFilterButton from './ServicePointFilters/ServicePointFilterButton'
import ServicePointSearch from './ServicePointSearch'
import * as Sentry from '@sentry/react'
import MapUtils from '../../../util/mapUtils'
import ServicePointGeolocationButton from './ServicePointGeolocationButton'
import ServicePointZoomButtons from './ServicePointZoomButtons'
import ServicePointListView from './ServicePointListView'
import classNames from 'classnames'
import ServicePointListMapToggleButtonProps from './ServicePointListMapToggleButton'
import ServicePointDetail, {
  ServiceDeliveryPointDetailsType,
} from './ServicePointDetail/ServicePointDetail'
import {ServicePointLocatorStoryblok} from 'types/storyblok-types'
import ServicePointFilters, {
  ChargingFilters,
  FuelFilters,
} from './ServicePointFilters/ServicePointFilters'
import {useRouter} from 'next/router'
import {constructDeliveryPointSearchRequestBody} from 'util/servicePointLocatorWebService'
import {useDebouncedCallback} from 'use-debounce'
import {Cluster} from '@react-google-maps/marker-clusterer'
import {Analytics} from 'lib/analytics'
import linkTypeChecker from 'util/linkTypeChecker'
import {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete'

export interface MarkerProps extends BaseMarkerProps {
  key: string
  icon: string
  selectedIcon: string
  deliveryPoint: ServiceDeliveryPoint
  type: ServiceDeliveryPointType
  availability: ServiceDeliveryPointAvailability
  position: {
    lat: number
    lng: number
  }
}

export type LibrariesProps = (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'visualization'
)[]

export type SearchInput = {
  value: any
  label: string
} | null

const libraries: LibrariesProps = ['geometry', 'places']

const keyStoredServiceDeliveryType = 'mapSelectedServiceDeliveryType'

type MapProps = {
  blok: ServicePointLocatorStoryblok
}

const Map = ({blok}: MapProps) => {
  const router = useRouter()

  const {isLoaded} = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLEMAPS_API_KEY!,
    libraries,
  })

  const contactLink = linkTypeChecker(blok.contact_link)

  const getStoredServiceDeliveryType = () => {
    if (typeof window !== 'undefined') {
      const value = localStorage.getItem(keyStoredServiceDeliveryType)
      if (value === 'CHARGE') {
        return ServiceDeliveryPointType.charge
      }
      return ServiceDeliveryPointType.fuel
    }
    return ServiceDeliveryPointType.fuel
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [map, setMap] = useState<google.maps.Map | null>()

  const [lastSearchPosition, setLastSearchPosition] = useState<
    google.maps.LatLng | undefined
  >()

  const [currentMapPosition, setCurrentMapPosition] = useState<
    google.maps.LatLng | undefined
  >()

  const [previousPosition, setPreviousPosition] = useState<
    google.maps.LatLng | undefined
  >()
  const [queryPosition, setQueryPosition] = useState<
    google.maps.LatLng | undefined
  >()
  const didSetQueryLocation = useRef<boolean>(false)

  const [currentZoom, setCurrentZoom] = useState<number>()
  const [previousZoom, setPreviousZoom] = useState<number>()

  const [serviceDeliveryPoints, setServiceDeliveryPoints] = useState<
    ServiceDeliveryPoint[]
  >([])

  const [markers, setMarkers] = useState<MarkerProps[]>([])
  const [selectedMarker, setSelectedMarker] = useState<MarkerProps | null>(null)
  const [selectedServiceType, setSelectedServiceType] =
    useState<ServiceDeliveryPointType>(getStoredServiceDeliveryType())

  const [deliveryPointInfoForDetails, setDeliveryPointInfoForDetails] =
    useState<ServiceDeliveryPointDetailsType | null>(null)

  const [userPosition, setUserPosition] = useState<
    google.maps.LatLng | undefined
  >()
  const [isCenteredOnUserLocation, setIsCenteredOnUserLocation] =
    useState<boolean>(false)

  const [displayList, setDisplayList] = useState<boolean | null>(null)
  const [displayFilters, setDisplayFilters] = useState<boolean | null>(null)
  const [displayListMobileView, setDisplayListMobileView] = useState<
    boolean | null
  >(false)

  const [inSearchMode, setInSearchMode] = useState<boolean>(false)
  const [searchInput, setSearchInput] = useState<{
    value: any
    label: string
  } | null>(null)
  const lastSearchRef = useRef<string | null>(null)

  useEffect(() => {
    if (searchInput?.label) {
      lastSearchRef.current = searchInput?.label
    } else {
      setLastSearchPosition(undefined)
    }
  }, [searchInput])

  const defaultZoomFueling = 14
  const defaultZoomCharging = 16

  const maxClusterZoomFueling = 12
  const maxClusterZoomCharging = 15

  const [defaultLocation] = useState(() => ({
    lat: 50.7338156,
    lng: 4.2069115,
  }))

  const [fuelTypeFilters, setFuelTypeFilters] = useState<FuelFilters>([])
  const [chargingTypeFilters, setChargingTypeFilters] =
    useState<ChargingFilters>()

  const [isSmallSize, setIsSmallSize] = useState<boolean>(false)
  const [selectedListItemOffset, setSelectedListItemOffset] =
    useState<number>(0)

  const holdOnIdleTimestamp = useRef<number>(0)

  const mapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    Analytics.getInstance().sendPageView(
      'service point locator',
      [],
      200,
      'other',
      router.locale,
    )
  }, [router.locale])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mq = window.matchMedia('screen and (max-width: 768px)')
      if (mq.matches) {
        setIsSmallSize(true)
      }
      mq.addEventListener('change', event => {
        const value = event.matches
        if (isSmallSize && value) {
          return
        } else if (!isSmallSize && !value) {
          return
        } else {
          setIsSmallSize(value)

          if (value) {
            setDisplayList(false)
          }
        }
      })
    }
  }, [displayList, isSmallSize])

  const containerStyle = {
    width: '100%',
    height: '100%',
    display: displayList ? (isSmallSize ? 'none' : 'block') : 'block',
  }

  const rootClassNames = classNames(
    'flex relative h-full max-h-screen w-full rounded-dats overflow-hidden',
    {
      'flex-grow': displayList,
      '': !displayList,
    },
  )

  const mapClassNames = classNames('font-base', {
    'w-full md:w-8/12 xl:w-9/12': displayList || deliveryPointInfoForDetails,
    'w-full': !displayList && !deliveryPointInfoForDetails,
  })

  // Hide POI markers.
  const mapStyles = [
    {
      featureType: 'all',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ]

  // Filters

  useEffect(() => {
    if (!isLoaded) {
      return
    }

    if (!defaultLocation) {
      return
    }

    // Handle DAW-271
    let selectedServiceType = ServiceDeliveryPointType.fuel
    if (router.query.fuelType) {
      const serviceType: string = router.query.fuelType.toString().toUpperCase()
      if (serviceType === 'FUEL') {
        selectedServiceType = ServiceDeliveryPointType.fuel
        setSelectedServiceType(selectedServiceType)
      } else if (serviceType === 'CHARGE') {
        selectedServiceType = ServiceDeliveryPointType.charge
        setSelectedServiceType(selectedServiceType)
      }
    }

    if (fuelTypeFilters.length > 0 || chargingTypeFilters) {
      return
    }

    if (didSetQueryLocation.current) {
      return
    }

    // Apply query filters, if any
    if (router.query.fuelFilters) {
      const fuelFilters = router.query.fuelFilters as string
      setSelectedServiceType(ServiceDeliveryPointType.fuel)
      setFuelTypeFilters(fuelFilters.split(','))
    } else if (router.query.minimumPower) {
      const onlyDats = router.query.onlyDats === 'true' ? true : false
      const onlyAvailable = router.query.onlyAvailable === 'true' ? true : false
      const connectors = router.query.connectors
      const minimumPower = router.query.minimumPower

      const chargingTypeFilters: ChargingFilters = {
        operators: onlyDats ? [ServiceDeliveryPointOperator.dats] : [],
        availability: onlyAvailable
          ? [ServiceDeliveryPointAvailability.available]
          : [],
        connectors: connectors
          ? ((connectors as string).split(',') as ChargingConnectorType[])
          : [],
        minimumPower: Number(minimumPower),
      }

      setSelectedServiceType(ServiceDeliveryPointType.charge)
      setChargingTypeFilters(chargingTypeFilters)
    }

    if (!queryPosition) {
      if (router.query.location && router.query.zoom) {
        const location = router.query.location as string
        const latitude = Number(location.split(',')[0])
        const longitude = Number(location.split(',')[1])
        const position = new google.maps.LatLng(latitude, longitude)

        const zoom = Number(router.query.zoom as string)
        setCurrentZoom(zoom)
        setQueryPosition(position)
      } else {
        // // Inject the default location
        const position = new google.maps.LatLng(
          defaultLocation.lat,
          defaultLocation.lng,
        )
        setCurrentZoom(
          selectedServiceType === ServiceDeliveryPointType.fuel
            ? defaultZoomFueling
            : defaultZoomCharging,
        )
        setQueryPosition(position)

        // Allow fetching the user location in this scenario
        getUserLocation()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    router,
    router.query,
    defaultLocation,
    isLoaded,
    fuelTypeFilters.length,
    chargingTypeFilters,
    queryPosition,
  ])

  useEffect(() => {
    debouncedConstructFilterQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fuelTypeFilters, chargingTypeFilters])

  const shouldApplyFuelFilters = (fuelFilters: string[]) => {
    setFuelTypeFilters(fuelFilters)
    setDisplayFilters(false)
  }

  const shouldApplyChargingFilters = (chargingFilters: ChargingFilters) => {
    setChargingTypeFilters(chargingFilters)
    setDisplayFilters(false)
  }

  const didFindFilterResults = (results: ServiceDeliveryPoint[]) => {
    setServiceDeliveryPoints(results)
  }

  const didResetFilters = () => {
    setFuelTypeFilters([])
    setChargingTypeFilters(undefined)
    debouncedFetchServiceDeliveryPoints()
  }

  const numberOfFiltersApplied = () => {
    if (selectedServiceType === ServiceDeliveryPointType.fuel) {
      return fuelTypeFilters.length
    } else if (chargingTypeFilters) {
      let number = 0
      if (
        chargingTypeFilters.operators.length === 1 &&
        chargingTypeFilters.operators[0] === ServiceDeliveryPointOperator.dats
      ) {
        number += 1
      }
      if (
        chargingTypeFilters.availability.length === 1 &&
        chargingTypeFilters.availability[0] ===
          ServiceDeliveryPointAvailability.available
      ) {
        number += 1
      }

      number += chargingTypeFilters.connectors.length
      if (chargingTypeFilters.minimumPower > 3.7) {
        number += 1
      }
      return number
    }
    return 0
  }

  const constructFilterQuery = () => {
    // Only overwrite the query (and thus the location) once we set
    // whatever location the router provided.
    if (!didSetQueryLocation.current) {
      return
    }

    let locationQuery = `${defaultLocation.lat},${defaultLocation.lng}`
    if (currentMapPosition) {
      locationQuery = `${currentMapPosition.lat()},${currentMapPosition.lng()}`
    }
    let zoomQuery =
      selectedServiceType === ServiceDeliveryPointType.fuel
        ? defaultZoomFueling
        : defaultZoomCharging
    if (currentZoom) {
      zoomQuery = currentZoom
    }

    if (fuelTypeFilters.length > 0) {
      const queryValue = (fuelTypeFilters as string[]).reduce(
        (result: string, current: string) => {
          if (result.length > 0) {
            return `${result},${current}`
          }
          return current
        },
        '',
      )

      // Update query path
      router.push(
        {
          pathname: router.asPath.split('?')[0],
          query: {
            location: locationQuery,
            zoom: zoomQuery,
            fuelFilters: queryValue,
          },
        },
        undefined,
        {shallow: true},
      )
    } else if (chargingTypeFilters) {
      var onlyDats = false
      if (
        chargingTypeFilters.operators.length === 1 &&
        chargingTypeFilters.operators[0] === ServiceDeliveryPointOperator.dats
      ) {
        onlyDats = true
      }

      var onlyAvailable = false
      if (
        chargingTypeFilters.availability.length === 1 &&
        chargingTypeFilters.availability[0] ===
          ServiceDeliveryPointAvailability.available
      ) {
        onlyAvailable = true
      }

      const connectorsQueryValue = (
        chargingTypeFilters.connectors as string[]
      ).reduce((result: string, current: string) => {
        if (result.length > 0) {
          return `${result},${current}`
        }
        return current
      }, '')

      const minimumPower = chargingTypeFilters.minimumPower

      // Update query path
      router.push(
        {
          pathname: router.asPath.split('?')[0],
          query: {
            location: locationQuery,
            zoom: zoomQuery,
            onlyDats: onlyDats,
            onlyAvailable: onlyAvailable,
            connectors: connectorsQueryValue,
            minimumPower: minimumPower,
          },
        },
        undefined,
        {shallow: true},
      )
    } else {
      router.push(
        {
          pathname: router.asPath.split('?')[0],
          query: {location: locationQuery, zoom: zoomQuery},
        },
        undefined,
        {shallow: true},
      )
    }
  }

  // Map view

  // Decide if we need to display the list.
  useEffect(() => {
    if (!map) {
      return
    }

    if (serviceDeliveryPoints.length > 0 && serviceDeliveryPoints.length < 20) {
      if (!isSmallSize) {
        setDisplayList(true)
      }
    } else {
      setDisplayList(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, serviceDeliveryPoints])

  useEffect(() => {
    // The map starts out full width. If the list gets displayed it will overlap a left and right part
    // of the original map bounds. This means the list may display points that are now
    // 'hidden' behind the list view or cut off from the visible part of the map.
    //
    // To fix, we should recalculate the points visible in the updated map bounds.

    if (!map) {
      return
    }

    if (!displayList) {
      return
    }

    const bounds = map.getBounds()
    if (!bounds) {
      return
    }

    const remainingPoints = serviceDeliveryPoints.filter(deliveryPoint => {
      let location = new google.maps.LatLng(
        deliveryPoint.latitude,
        deliveryPoint.longitude,
      )
      return MapUtils.boundsContainsLocation(bounds, location)
    })

    setServiceDeliveryPoints(remainingPoints)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayList])

  // Navigate to the query location
  useEffect(() => {
    if (isLoaded && queryPosition && !didSetQueryLocation.current) {
      setCurrentMapPosition(queryPosition)
      map?.panTo(queryPosition)
      didSetQueryLocation.current = true
    }
  }, [isLoaded, map, queryPosition])

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  const onIdle = React.useCallback(
    function callback() {
      if (!map) {
        return
      }

      if (inSearchMode) {
        return
      }

      console.log('on idle')

      // Only overwrite the map position and zoom once we've
      // set it based on the query.
      if (didSetQueryLocation.current && !inSearchMode) {
        setCurrentMapPosition(map.getCenter())
        setCurrentZoom(map.getZoom())
        debouncedConstructFilterQuery()
      }

      if (userPosition && currentMapPosition) {
        if (MapUtils.distanceBetween(userPosition, currentMapPosition) > 3000) {
          setIsCenteredOnUserLocation(false)
        }
      }

      // Check if the map moved far enough that we need to reload our
      // delivery points. Only update the previous position when it either
      // doesn't exist yet or we moved far enough to reload. Otherwise
      // we have a bug where we can move the map in very small iterations and
      // never receive reloaded service points.
      if (currentMapPosition && previousPosition) {
        let distance =
          MapUtils.distanceBetween(currentMapPosition, previousPosition) / 1000
        let zoomDifference = 0
        if (currentZoom && previousZoom) {
          zoomDifference = Math.abs(currentZoom - previousZoom)
        }

        // Calculate the minimum distance we need to move before reloading.
        // This depends on the visible radius of the map. We want the user
        // to have moved half the area covered on the map before we reload.
        const visibleNumberOfKm = MapUtils.getVisibleRadius(map) / 2

        if (distance < visibleNumberOfKm && zoomDifference < 1) {
          setInSearchMode(false)
          return
        } else {
          setPreviousPosition(currentMapPosition)
          setPreviousZoom(currentZoom)
        }
      }

      if (!previousPosition) {
        setPreviousPosition(currentMapPosition)
      }

      if (!previousZoom) {
        setPreviousZoom(currentZoom)
      }

      const timeSinceHoldOnIdle = Date.now() - holdOnIdleTimestamp.current
      if (timeSinceHoldOnIdle > 1500) {
        debouncedFetchServiceDeliveryPoints()
        holdOnIdleTimestamp.current = 0
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      map,
      currentMapPosition,
      previousPosition,
      previousZoom,
      userPosition,
      currentZoom,
    ],
  )

  const onZoomChanged = React.useCallback(
    function callback() {
      if (!map) {
        return
      }
      if (didSetQueryLocation.current) {
        setCurrentZoom(map.getZoom())
      }
    },
    [map],
  )

  const constructMarkersList = () => {
    let updatedMarkers: any[] = serviceDeliveryPoints.map(e => {
      var icon = '/images/map/map-icon-fuel-available.svg'
      var selectedIcon = '/images/map/map-icon-fuel-available-selected.svg'
      var zIndex = 10

      if (e.type === ServiceDeliveryPointType.fuel) {
        icon = '/images/map/map-icon-fuel-available.svg'
        selectedIcon = '/images/map/map-icon-fuel-available-selected.svg'
      } else if (e.chargingLocation) {
        if (
          e.chargingLocation.availability ===
          ServiceDeliveryPointAvailability.available
        ) {
          if (e.chargingLocation.operatorName.includes('DATS')) {
            icon = '/images/map/map-icon-fuel-available.svg'
            selectedIcon = '/images/map/map-icon-fuel-available-selected.svg'
            zIndex = 99
          } else {
            icon = '/images/map/map-icon-charging-available.svg'
            selectedIcon =
              '/images/map/map-icon-charging-available-selected.svg'
            zIndex = 98
          }
        } else {
          if (e.chargingLocation.operatorName.includes('DATS')) {
            icon = '/images/map/map-icon-fuel-unavailable.svg'
            selectedIcon = '/images/map/map-icon-fuel-unavailable-selected.svg'
            zIndex = 97
          } else {
            icon = '/images/map/map-icon-charging-unavailable.svg'
            selectedIcon =
              '/images/map/map-icon-charging-unavailable-selected.svg'
            zIndex = 96
          }
        }
      }

      const key = e.chargingLocation
        ? `${e.chargingLocation.id}`
        : `${e.fuelStation?.id ?? ''}`
      const marker: MarkerProps = {
        key: key,
        icon: icon,
        selectedIcon: selectedIcon,
        deliveryPoint: e,
        type: e.type,
        availability:
          e.chargingLocation?.availability ??
          ServiceDeliveryPointAvailability.available,
        position: {
          lat: e.latitude,
          lng: e.longitude,
        },
        zIndex,
      }
      return marker
    })

    setMarkers(updatedMarkers)
  }

  useEffect(() => {
    debouncedConstructMarkersList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDeliveryPoints, userPosition, lastSearchPosition])

  // Debounces fetchServiceDeliveryPoint calls.
  const debouncedConstructMarkersList = useDebouncedCallback(() => {
    constructMarkersList()
  }, 1000)

  const fetchServiceDeliveryPoints = React.useCallback(
    async (customSearchRadius?: number, attempt: number = 1) => {
      if (!map) {
        setInSearchMode(false)
        return
      }

      console.log('Fetching service delivery points')

      setIsLoading(true)

      let searchRadius =
        customSearchRadius ?? MapUtils.calculateSearchRadius(map)

      let searchPosition = new google.maps.LatLng(
        defaultLocation.lat,
        defaultLocation.lng,
      )
      if (currentMapPosition) {
        searchPosition = currentMapPosition
      }

      let body = constructDeliveryPointSearchRequestBody(
        searchPosition.lat(),
        searchPosition.lng(),
        searchRadius,
        selectedServiceType,
        fuelTypeFilters,
        chargingTypeFilters,
      )

      try {
        const response = await fetch(
          `https://${process.env
            .NEXT_PUBLIC_DOMAIN!}/api/service_point_locator`,
          {
            method: 'POST',
            body: JSON.stringify(body),
          },
        )

        const data: ServiceDeliveryPointSearchResponse = await response.json()

        // console.log('Attempt: ', attempt ?? 1)
        // console.log('Coordinates: ', searchPosition.lat(), searchPosition.lng())
        // console.log('Search radius: ', searchRadius)

        if (!response.ok) {
          setInSearchMode(false)

          throw {
            msg:
              'Error fetching service delivery points: ' + response.statusText,
            status: response.status,
          }
        }

        // console.log(
        //   'Found ' +
        //     data.serviceDeliveryPoints.length +
        //     ' points with radius: ',
        //   searchRadius,
        // )
        let visiblePointsFitInMapBounds = true
        let visiblePoints: ServiceDeliveryPoint[] = []
        // Take only the points visible in the rectangular map.
        let visibleRegion = map.getBounds()
        if (visibleRegion) {
          visiblePoints = data.serviceDeliveryPoints.filter(deliveryPoint => {
            let location = new google.maps.LatLng(
              deliveryPoint.latitude,
              deliveryPoint.longitude,
            )
            return MapUtils.boundsContainsLocation(visibleRegion!, location)
          })
          if (visiblePoints.length === 0) {
            // Fall back on all points
            visiblePoints = data.serviceDeliveryPoints
            visiblePointsFitInMapBounds = false
          }
        } else {
          visiblePointsFitInMapBounds = false
          visiblePoints = data.serviceDeliveryPoints
        }

        // console.log(
        //   'Found ' + visiblePoints.length + ' points at coordinates: ',
        //   searchPosition,
        // )

        if (visiblePoints.length === 0 && inSearchMode) {
          let newSearchRadius = searchRadius + 25000

          if (newSearchRadius < 250000) {
            fetchServiceDeliveryPoints(newSearchRadius, attempt + 1)
          } else {
            setIsLoading(false)
            map?.setZoom(9)
            setInSearchMode(false)
          }
          return
        }

        setIsLoading(false)

        // console.log(
        //   'Visible points fit in map bounds: ',
        //   visiblePointsFitInMapBounds,
        // )
        if (
          (customSearchRadius || inSearchMode) &&
          !visiblePointsFitInMapBounds
        ) {
          // We have retried the request with a larger search radius until
          // we received results. Make sure the map fits around them.

          // Collect the coordinates
          const coordinates = data.serviceDeliveryPoints.map(deliveryPoint => {
            return new google.maps.LatLng(
              deliveryPoint.latitude,
              deliveryPoint.longitude,
            )
          })

          // Construct the bounds of all the coordinates
          var bounds = new google.maps.LatLngBounds()
          for (const latLng of coordinates) {
            bounds.extend(latLng)
          }

          // Adjust the bounds so the current map center remains the center.
          const centeredBounds = MapUtils.adjustBoundsToKeepCenter(
            map.getCenter()!,
            bounds,
          )

          // Update the map
          map.setCenter(centeredBounds.getCenter())
          map.fitBounds(centeredBounds)
        }

        holdOnIdleTimestamp.current = Date.now()
        setInSearchMode(false)

        // Reset the last offset in the list view as we are going to work with new
        // delivery points.
        setSelectedListItemOffset(0)
        setServiceDeliveryPoints(visiblePoints)

        if (searchInput && searchInput.label.length > 0) {
          sendAnalytics(visiblePoints.length)
        }
      } catch (err) {
        setIsLoading(false)
        setInSearchMode(false)
        console.error(err)
        Sentry.captureException(err)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      map,
      selectedServiceType,
      fuelTypeFilters,
      chargingTypeFilters,
      defaultLocation.lat,
      defaultLocation.lng,
      currentMapPosition,
      inSearchMode,
      constructDeliveryPointSearchRequestBody,
    ],
  )

  // Debounces fetchServiceDeliveryPoint calls.
  const debouncedFetchServiceDeliveryPoints = useDebouncedCallback(() => {
    fetchServiceDeliveryPoints()
  }, 500)

  const debouncedConstructFilterQuery = useDebouncedCallback(() => {
    constructFilterQuery()
  }, 500)

  useEffect(() => {
    if (isLoaded) {
      // Start the delivery point search with iteration 0.
      debouncedFetchServiceDeliveryPoints()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceType, isLoaded])

  const onFuelServiceTypeClicked = () => {
    if (selectedServiceType === ServiceDeliveryPointType.charge) {
      setSelectedServiceType(ServiceDeliveryPointType.fuel)
      map?.setZoom(defaultZoomFueling)
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          keyStoredServiceDeliveryType,
          ServiceDeliveryPointType.fuel,
        )
      }
    }
  }

  const onChargeServiceTypeClicked = () => {
    if (selectedServiceType === ServiceDeliveryPointType.fuel) {
      setSelectedServiceType(ServiceDeliveryPointType.charge)
      map?.setZoom(defaultZoomCharging)
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          keyStoredServiceDeliveryType,
          ServiceDeliveryPointType.charge,
        )
      }
    }
  }

  const onFilterClicked = () => {
    // This can only display the filters, because the
    // outside event wrapper in the filter view will handle the
    // closing when we tap the filter close button.
    setDisplayFilters(true)
  }

  const onUserLocationClicked = () => {
    getUserLocation()
  }

  const onZoomInClicked = () => {
    if (map) {
      const currentZoom = map.getZoom()
      if (currentZoom) {
        map.setZoom(currentZoom + 1)
        onZoomChanged()
      }
    }
  }

  const onZoomOutClicked = () => {
    if (map) {
      const currentZoom = map.getZoom()
      if (currentZoom) {
        map.setZoom(currentZoom - 1)
        onZoomChanged()
      }
    }
  }

  const shouldNavigateToDeliveryPoint = (
    point: ServiceDeliveryPoint,
    offset: number,
  ) => {
    map?.panTo(new google.maps.LatLng(point.latitude, point.longitude))
    // Zoom if necessary, so no clusters remain visible.
    if (currentZoom && currentZoom < 13) {
      map?.setZoom(13)
    }

    // Set the point as selected.
    const key = point.chargingLocation
      ? `${point.chargingLocation.id}`
      : `${point.fuelStation?.id ?? ''}`

    const relatedMarker = markers.find(marker => {
      return marker.key === key
    })
    if (relatedMarker) {
      setSelectedMarker(relatedMarker)
    }

    setSelectedListItemOffset(offset)
  }

  const getUserLocation = () => {
    if (navigator.geolocation) {
      setIsLoading(true)
      navigator.geolocation.getCurrentPosition(
        didRetrieveUserPosition,
        geoLocationError,
      )
    } else {
      alert('Geolocation is not supported by this browser.')
    }
  }

  const didRetrieveUserPosition = async (position: any) => {
    setIsLoading(false)

    const lat = position.coords.latitude
    const lng = position.coords.longitude

    const userLocation = new google.maps.LatLng(lat, lng)
    setUserPosition(userLocation)

    const zoom =
      selectedServiceType === ServiceDeliveryPointType.fuel
        ? defaultZoomFueling
        : defaultZoomCharging

    map?.panTo(userLocation)
    map?.setZoom(zoom)

    setCurrentMapPosition(userLocation)
    setIsCenteredOnUserLocation(true)
    setInSearchMode(true)
    debouncedFetchServiceDeliveryPoints()
  }

  const geoLocationError = async (error: GeolocationPositionError) => {
    setIsLoading(false)
    console.log('Geolocation error: ', error)
    if (navigator.permissions) {
      const permissions = await navigator.permissions.query({
        name: 'geolocation',
      })
      console.log('Geolocation permissions: ', permissions)
    }
  }

  const setCurrentMapPositionFromSearch = React.useCallback(
    (mapPosition: google.maps.LatLng) => {
      if (!map) {
        return
      }

      if (mapPosition === currentMapPosition) {
        return
      }

      setInSearchMode(true)
      setLastSearchPosition(mapPosition)
      setCurrentMapPosition(mapPosition)
      map.setZoom(
        selectedServiceType === ServiceDeliveryPointType.fuel
          ? defaultZoomFueling
          : defaultZoomCharging,
      )
      map?.panTo(mapPosition)
      debouncedFetchServiceDeliveryPoints()

      if (isSmallSize) {
        setDisplayListMobileView(true)
      }
    },
    [
      currentMapPosition,
      debouncedFetchServiceDeliveryPoints,
      isSmallSize,
      map,
      selectedServiceType,
    ],
  )

  const search = (input: SearchInput) => {
    console.log('Searching for: ', input)
    setSearchInput(input)
    if (input && input.value && input.value !== '') {
      geocodeByPlaceId(input.value.place_id)
        .then(results => getLatLng(results[0]))
        .then(({lat, lng}) => {
          setCurrentMapPositionFromSearch(new google.maps.LatLng(lat, lng))
        })
        .catch(error => console.error(error))
    }
  }

  const onClusterClick = React.useCallback(
    (cluster: Cluster) => {
      if (!map) {
        return
      }
      const newZoom = (map.getZoom() ?? currentZoom ?? defaultZoomCharging) + 1
      if (cluster.center) {
        map.setCenter(cluster.center)
      }
      map.setZoom(newZoom)
      onZoomChanged()

      // const clusterBounds = cluster.getBounds()
      // if (clusterBounds) {
      //   var ne = clusterBounds.getNorthEast()
      //   var sw = clusterBounds.getSouthWest()

      //   // Because the actual boundaries displaying points are smaller than the map,
      //   // we have to adjust for that.
      //   let latAdjustment = (ne.lat() - sw.lat()) * 0.05
      //   let lngAdjustment = (ne.lng() - sw.lng()) * 0.05

      //   let adjustedNorthEast = new google.maps.LatLng(
      //     ne.lat() + latAdjustment,
      //     ne.lng() + lngAdjustment,
      //   )
      //   let adjustedSouthWest = new google.maps.LatLng(
      //     sw.lat() - latAdjustment,
      //     sw.lng() - lngAdjustment,
      //   )
      //   let adjustedBounds = new google.maps.LatLngBounds(
      //     adjustedSouthWest,
      //     adjustedNorthEast,
      //   )

      //   console.log('On cluster click')
      //   clusterClickTimeStamp.current = Date.now()
      //   map.fitBounds(adjustedBounds)
      // }
    },
    [currentZoom, map, onZoomChanged],
  )

  const renderMarkers = React.useCallback(() => {
    if (markers.length === 0 && !userPosition && !lastSearchPosition) {
      return null
    } else {
      let currentLocationMarker = null
      if (userPosition) {
        currentLocationMarker = {
          key: 'userLocationMarker',
          icon: '/images/map/map-icon-current-location.svg',
          position: {
            lat: userPosition.lat(),
            lng: userPosition.lng(),
          },
        }
      }

      let searchLocationMarker = null

      if (lastSearchPosition) {
        searchLocationMarker = {
          key: 'lastSearchPositionMarker',
          icon: '/images/map/map-icon-search-location.svg',
          position: {
            lat: lastSearchPosition.lat(),
            lng: lastSearchPosition.lng(),
          },
        }
      }

      return (
        <>
          {currentLocationMarker ? (
            <MarkerF
              key={currentLocationMarker.key}
              icon={currentLocationMarker.icon}
              zIndex={999}
              position={currentLocationMarker.position}
            />
          ) : null}

          {searchLocationMarker ? (
            <MarkerF
              key={searchLocationMarker.key}
              icon={searchLocationMarker.icon}
              zIndex={9}
              position={searchLocationMarker.position}
            />
          ) : null}

          <MarkerClustererF
            averageCenter={true}
            maxZoom={
              selectedServiceType === ServiceDeliveryPointType.fuel
                ? maxClusterZoomFueling
                : maxClusterZoomCharging
            }
            gridSize={200}
            zoomOnClick={false}
            onClick={onClusterClick}
            options={{
              styles: [
                {
                  url: '/images/map/map-icon-cluster.svg',
                  height: 64,
                  width: 64,
                  textColor: '#FFFFFF',
                  textSize: 20,
                  fontFamily: '__Rubik_1f3fdf',
                  fontWeight: 'semibold',
                },
              ],
            }}
          >
            {clusterer => (
              <>
                {markers.map((marker, index) => {
                  const onClick = (e: google.maps.MapMouseEvent) => {
                    setSelectedMarker(marker)
                    setDeliveryPointInfoForDetails({
                      longitude: marker.deliveryPoint.longitude,
                      latitude: marker.deliveryPoint.latitude,
                      keyID: marker.key,
                      type: marker.deliveryPoint.type,
                      address: marker.deliveryPoint.address,
                    })
                    marker?.onClick?.(e)
                    map?.panTo(marker.position)

                    Analytics.getInstance().sendClickStoreDetailEvent(
                      marker.deliveryPoint.fuelStation?.name ??
                        marker.deliveryPoint.chargingLocation?.operatorName,
                    )
                  }

                  const isSelected = marker.key === selectedMarker?.key
                  const icon = isSelected ? marker.selectedIcon : marker.icon

                  return (
                    <MarkerF
                      {...marker}
                      key={marker.key}
                      onClick={onClick}
                      icon={icon}
                      clusterer={clusterer}
                      noClustererRedraw={
                        index < markers.length - 1 ? true : false
                      }
                      zIndex={marker.zIndex}
                    />
                  )
                })}
              </>
            )}
          </MarkerClustererF>
        </>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    map,
    markers,
    selectedMarker,
    userPosition,
    lastSearchPosition,
    onClusterClick,
  ])

  /* Analytics */

  const sendAnalytics = (serviceDeliveryPointCount: number) => {
    let active_list_filters: {name: string; category: string}[] = []

    const category =
      selectedServiceType === ServiceDeliveryPointType.charge
        ? 'charging'
        : 'fueling'

    active_list_filters.push({
      name: category,
      category: 'type',
    })

    // Collect all active filters apart from the one just changed.

    if (
      selectedServiceType === ServiceDeliveryPointType.charge &&
      chargingTypeFilters
    ) {
      if (
        chargingTypeFilters.operators[0] === ServiceDeliveryPointOperator.dats
      ) {
        active_list_filters.push({
          name: 'only dats24',
          category: category,
        })
      }

      if (
        chargingTypeFilters.availability[0] ===
        ServiceDeliveryPointAvailability.available
      ) {
        active_list_filters.push({
          name: 'only available',
          category: category,
        })
      }

      if (chargingTypeFilters.minimumPower) {
        active_list_filters.push({
          name: `mimimumPower ${chargingTypeFilters.minimumPower}`,
          category: category,
        })
      }

      chargingTypeFilters.connectors?.forEach((connector: string) => {
        active_list_filters.push({
          name: connector,
          category: category,
        })
      })
    } else {
      fuelTypeFilters?.forEach((filter: string) => {
        let filterFullName = ''
        switch (filter) {
          case 'A':
            filterFullName = 'adblue'
            break
          case 'C':
            filterFullName = 'cng'
            break
          case 'D':
            filterFullName = 'diesel'
            break
          case 'P':
            filterFullName = 'super98'
            break
          case 'U':
            filterFullName = 'euro95'
            break
          case 'W':
            filterFullName = 'hydrogen'
            break
          default:
            filterFullName = ''
        }

        active_list_filters.push({
          name: filterFullName,
          category: category,
        })
      })
    }

    if (searchInput) {
      Analytics.getInstance().sendStoreSearchEvent(
        searchInput.label,
        serviceDeliveryPointCount,
        active_list_filters,
      )
    }
  }

  return (
    <div className="mx-[20px] h-screen lg:mx-[100px] lg:h-[640px]">
      {isLoaded ? (
        <div className={rootClassNames}>
          {displayListMobileView && !deliveryPointInfoForDetails ? (
            <div
              className={`absolute size-full md:relative md:hidden md:w-4/12 xl:w-3/12`}
            >
              <ServicePointListView
                setDeliveryPointInfoForDetails={setDeliveryPointInfoForDetails}
                userPosition={userPosition}
                inSearchMode={inSearchMode}
                listViewOffset={selectedListItemOffset}
                deliveryPoints={serviceDeliveryPoints}
                deliveryType={selectedServiceType}
                onClick={(point, offset) =>
                  shouldNavigateToDeliveryPoint(point, offset)
                }
                setDisplayList={setDisplayListMobileView}
                displayList={displayListMobileView}
              />
            </div>
          ) : null}
          {deliveryPointInfoForDetails ? (
            <div
              className={`absolute size-full md:relative md:w-4/12 xl:w-3/12`}
            >
              <ServicePointDetail
                deliveryPointInfo={deliveryPointInfoForDetails}
                userPosition={userPosition}
                setDeliveryPointInfoForDetails={e => {
                  setDeliveryPointInfoForDetails(e)
                  setSelectedMarker(null)
                }}
                contactLink={contactLink}
              />
            </div>
          ) : displayList ? (
            <div
              className={`absolute hidden size-full md:relative md:block md:w-4/12 xl:w-3/12`}
            >
              <ServicePointListView
                setDeliveryPointInfoForDetails={setDeliveryPointInfoForDetails}
                userPosition={userPosition}
                inSearchMode={inSearchMode}
                listViewOffset={selectedListItemOffset}
                deliveryPoints={serviceDeliveryPoints}
                deliveryType={selectedServiceType}
                onClick={(point, offset) =>
                  shouldNavigateToDeliveryPoint(point, offset)
                }
                setDisplayList={setDisplayList}
                displayList={displayList}
              />
            </div>
          ) : null}
          <div ref={mapRef} className={mapClassNames}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={
                currentZoom ??
                (selectedServiceType === ServiceDeliveryPointType.fuel
                  ? defaultZoomFueling
                  : defaultZoomCharging)
              }
              center={currentMapPosition}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onIdle={onIdle}
              onZoomChanged={onZoomChanged}
              options={{
                styles: mapStyles,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
                maxZoom: 18,
                minZoom: 8,
              }}
            >
              {renderMarkers()}
              <div className="absolute left-4 top-20 z-10 xl:top-4">
                <ServicePointTypeToggle
                  onFuelServiceTypeClicked={onFuelServiceTypeClicked}
                  onChargeServiceTypeClicked={onChargeServiceTypeClicked}
                  selectedServiceType={selectedServiceType}
                />
              </div>
              <div className="absolute right-4 top-20 z-10 xl:top-4">
                <ServicePointFilterButton
                  isFilterOpen={displayFilters ?? false}
                  numberOfFiltersApplied={numberOfFiltersApplied()}
                  onFilterClicked={onFilterClicked}
                />
              </div>
              {
                <ServicePointSearch
                  useWideMargin={!displayFilters}
                  didStartSearch={search}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  userPosition={userPosition}
                  // setDisplayList={setDisplayList}
                  displayListMobileView={displayListMobileView}
                  deliveryPointDetails={deliveryPointInfoForDetails}
                />
              }

              <div className="absolute bottom-6 right-2 z-10 hidden md:block">
                <ServicePointZoomButtons
                  onZoomInClicked={onZoomInClicked}
                  onZoomOutClicked={onZoomOutClicked}
                />
              </div>
              {!displayListMobileView ? (
                <>
                  <div className="absolute bottom-2 right-[4px] z-50 md:bottom-28">
                    <ServicePointGeolocationButton
                      isActive={isCenteredOnUserLocation}
                      onClicked={onUserLocationClicked}
                    />
                  </div>
                  <div className="absolute bottom-4 z-30 flex w-full items-center justify-center md:hidden">
                    <ServicePointListMapToggleButtonProps
                      onClicked={() => {
                        setDisplayListMobileView(true)
                      }}
                      isMapActive={true}
                    />
                  </div>
                </>
              ) : null}
              {displayFilters && map ? (
                <ServicePointFilters
                  map={map}
                  selectedServiceType={selectedServiceType}
                  fuelTypeFilters={fuelTypeFilters}
                  chargingTypeFilters={chargingTypeFilters}
                  searchTerm={lastSearchRef.current ?? ''}
                  setFuelTypeFilters={shouldApplyFuelFilters}
                  setChargingTypeFilters={shouldApplyChargingFilters}
                  didFindResults={didFindFilterResults}
                  didResetFilters={didResetFilters}
                  shouldClose={() => setDisplayFilters(false)}
                />
              ) : null}

              <div className="absolute left-1/2 top-1/2 ml-[-32px] mt-[-32px]">
                <div
                  className={`mapSpinner ${isLoading ? 'block' : 'hidden'}`}
                ></div>
              </div>
            </GoogleMap>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Map
